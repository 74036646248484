@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* header */

.app-header {
  display: flex;
  flex-direction: column;
  background-color: #99002f;
  color: white;
  position: fixed;
  width: 100%;
}
.app-header-logo {
 text-align: center;
 padding: 5px;
 font-size: 12px;
 font-family: 'Archivo Black', sans-serif;
}
.app-header-menu {
  width: 100%;
  text-align: center;
}
.app-header-menu ul{
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.app-header-menu ul li {
  width: 33%;
  text-align: center;
  padding: 15px;
}
.app-header-menu ul li a {
  text-decoration: none;
  color: white;
} 


/* about me*/
.app-aboutme {
  background: url("./img/backgroundhigh.jpg");
  width: 100%;
  object-fit: cover;
  margin-top: 0;
  padding-top: 150px;
  padding-bottom: 50px;
}
.app-aboutme-content {
  color: white;
}
.app-aboutme-content img {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 0px 9px 3px  #222222; 
  box-shadow: 0px 0px 9px 3px #222222;
}
.app-aboutme-content p {
  margin: 0;
  padding: 20px;
  font-weight: 100;
  font-size: 17px;
}
.app-aboutme-socialmedia {
  display: flex;
  flex-direction: row;
}
.app-aboutme-socialmedia a {
  text-decoration: none;
  color: white;
  padding: 30px;
}
.app-aboutme-socialmedia a svg {
  width: 40px;
  height: 40px;
}

/* Skills*/

.skills-content {
  padding-top: 100px;
  text-align: center;
}
.skills-content h2 {
  font-size: 50px;
  color: #57061e;
}
.skill-boxes {
  text-align: center;
  display: block;
  margin: auto;
  border: 2px solid #99002f;
  border-radius: 3px;
  height: 230px;
  margin-top: 10px;
  background-color: #8d062f;
  color: white;
  width: 95%;
}
.skill-boxes svg{
  margin-top: 20px;
}
.skill-boxes p {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.app-cv-button {
  margin-top: 50px;
}
.app-cv-button-button {
  font-size: 18px;
  padding: 5px;
  width: 180px;
  background-color: #8d062f;
  color: white;
  border-radius: 3px;
}

/* Projects */

#projects-header h2 {
  padding-top: 150px;
  text-align: center;
  font-size: 50px;
  color: #57061e;
}
.project-row {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.project-row-image img {
  width: 95%;
  display: block;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.project-row-information {
  width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.project-row-information-header-and-links h3{
  font-size: 25px;
}
.project-links {
  display: flex;
  align-items: center;
}
.project-links a{
  border: 2px solid #8d062f;
  background-color: #e06f9159;
  margin-right: 10px;
  border-radius: 3px;
  padding: 1px 5px;
  font-size: 15px;
  text-decoration: none;
  color: #57061e;
  font-weight: bold;
  align-items: center;
  display: flex;
}
.project-links a svg {
  margin: 3px;
}
.project-links a p {
  margin: 0px;
}
.project-description {
  margin-bottom: 50px;
}

.app-footer {
  text-align: center;
  padding: 10px;
  background-color: #99002f;
  color: white;
}

@media only screen and (min-width: 600px) {

  /* header */

  .app-header {
    flex-direction: row;
    align-items: center;
  }
  .app-header-logo {
   font-size: 8px;
  }
  .app-header-menu ul li a {
    font-size: 20px;
  } 
  
  
  /* about me*/

  .app-aboutme-content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .app-aboutme-content img {
    width: 30%;
    height: 30%;
  }
  .app-aboutme-content p {
    font-size: 22px;
  }
  .app-aboutme-socialmedia {
    justify-content: center;
  }
  .app-aboutme-socialmedia a {
    padding: 50px;
  }
  .app-aboutme-socialmedia a svg {
    width: 50px;
    height: 50px;
  }
  
  /* Skills*/
  
  .skill-boxes {
    width: 50%;
  }
  .app-cv-button {
    margin-top: 70px;
  }
  .app-cv-button-button {
    font-size: 20px;
    padding: 10px;
    width: 200px;
    color: white;
  }
  
  /* Projects */
 
  .project-row {
    width: 70%;
  }
  .project-row-information-header-and-links h3{
    font-size: 30px;
  }
  .project-description {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1000px) {

  .app-header-logo {
   font-size: 10px;
  }
  
  /* about me*/
  .app-aboutme {
    background: url("./img/backgroundwid.jpg");
  }
  .app-aboutme-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .app-aboutme-content img {
    width: 20%;
    height: 20%;
  }
  .app-aboutme-content-text {
    padding: 50px;
  }
  
  /* Skills*/

  .skills-row {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .skill-boxes {
    padding: 5px;
    margin: 5px;
  }
  .app-cv-button-button {
    font-size: 20px;
    padding: 7px;
    width: 200px;
  }
  
  /* Projects */
  
  .project-row {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .project-row-image {
    width: 40%;
  }
  .project-row-image img {
    width: 100%;
  }
  .project-row-information {
    width: 90%;
    padding-left: 50px;
  }
  .project-row-information-header-and-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .project-links {
    display: flex;
    align-items: center;
  }

}
@media only screen and (min-width: 1700px) {
  
  /* about me*/

  .app-aboutme-content {
    width: 65%;
  }
  /* Skills*/
  .skills-row {
    width: 65%;
  }
  /* Projects */
  .project-row {
    width: 65%;
  }
  .project-row div {
    margin-top: 20px;
  }
}


