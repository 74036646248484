.project-content {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 150px;
  padding-bottom: 50px;
}
.project-content p {
  font-size: 16px;
  color: rgb(53, 0, 0);
}
.project-content h2 {
  font-size: 28px;
  color: #690624;
}
.project-skill-list {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  list-style: none;
}
.project-skill-list li {
  border: 1px solid #8d062f;
  background-color: #e06f9159;
  border-radius: 3px;
  padding: 1px 10px;
  margin-right: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.project-skill-list li svg {
  padding: 5px;
  color: rgb(73, 1, 1);
}
.app-process-button {
  font-size: 15px;
  padding: 10px;
  width: 325px;
  background-color: #8d062f;
  color: white;
  border-radius: 3px;
}
.project-picture {
  text-align: center;
  margin-top: 50px;
}
.project-picture img {
  border-radius: 3px;
  -webkit-box-shadow: 0px 6px 7px 0px #636363;
  box-shadow: 0px 6px 7px 0px #636363;
  width: 100%;
}
.project-picture p {
  font-style: italic;
  font-size: 15px;
}
.header-with-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project-content h3 {
  font-size: 25px;
  text-decoration: underline;
  color: #700726;
}
h4 {
  font-size: 20px;
  color: #700726;
}
h5 {
  font-size: 18px;
  color: #700726;
}

@media only screen and (min-width: 600px) {
  .project-content {
    width: 80%;
    padding-top: 120px;
  }
  .project-skill-list li svg {
    padding: 5px;
    color: rgb(73, 1, 1);
  }
  .project-picture img {
    width: 70%;
  }
}
@media only screen and (min-width: 1000px) {
  .project-content {
    width: 50%;
  }
  .project-content p {
    font-size: 17px;
  }
  .project-picture img {
    width: 70%;
  }
}
@media only screen and (min-width: 1700px) {
  .project-content {
    width: 40%;
  }
  .project-content p {
    font-size: 20px;
  }
  .project-content h2 {
    font-size: 35px;
  }
  .project-picture img {
    width: 50%;
  }
  .project-content h3 {
    font-size: 27px;
  }
  h4 {
    font-size: 23px;
  }
  h5 {
    font-size: 20px;
  }
}
