@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* header */

.app-header {
  display: flex;
  flex-direction: column;
  background-color: #99002f;
  color: white;
  position: fixed;
  width: 100%;
}
.app-header-logo {
 text-align: center;
 padding: 5px;
 font-size: 12px;
 font-family: 'Archivo Black', sans-serif;
}
.app-header-menu {
  width: 100%;
  text-align: center;
}
.app-header-menu ul{
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.app-header-menu ul li {
  width: 33%;
  text-align: center;
  padding: 15px;
}
.app-header-menu ul li a {
  text-decoration: none;
  color: white;
} 


/* about me*/
.app-aboutme {
  background: url(/static/media/backgroundhigh.e7fc5609.jpg);
  width: 100%;
  object-fit: cover;
  margin-top: 0;
  padding-top: 150px;
  padding-bottom: 50px;
}
.app-aboutme-content {
  color: white;
}
.app-aboutme-content img {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto; 
  box-shadow: 0px 0px 9px 3px #222222;
}
.app-aboutme-content p {
  margin: 0;
  padding: 20px;
  font-weight: 100;
  font-size: 17px;
}
.app-aboutme-socialmedia {
  display: flex;
  flex-direction: row;
}
.app-aboutme-socialmedia a {
  text-decoration: none;
  color: white;
  padding: 30px;
}
.app-aboutme-socialmedia a svg {
  width: 40px;
  height: 40px;
}

/* Skills*/

.skills-content {
  padding-top: 100px;
  text-align: center;
}
.skills-content h2 {
  font-size: 50px;
  color: #57061e;
}
.skill-boxes {
  text-align: center;
  display: block;
  margin: auto;
  border: 2px solid #99002f;
  border-radius: 3px;
  height: 230px;
  margin-top: 10px;
  background-color: #8d062f;
  color: white;
  width: 95%;
}
.skill-boxes svg{
  margin-top: 20px;
}
.skill-boxes p {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.app-cv-button {
  margin-top: 50px;
}
.app-cv-button-button {
  font-size: 18px;
  padding: 5px;
  width: 180px;
  background-color: #8d062f;
  color: white;
  border-radius: 3px;
}

/* Projects */

#projects-header h2 {
  padding-top: 150px;
  text-align: center;
  font-size: 50px;
  color: #57061e;
}
.project-row {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.project-row-image img {
  width: 95%;
  display: block;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.project-row-information {
  width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.project-row-information-header-and-links h3{
  font-size: 25px;
}
.project-links {
  display: flex;
  align-items: center;
}
.project-links a{
  border: 2px solid #8d062f;
  background-color: #e06f9159;
  margin-right: 10px;
  border-radius: 3px;
  padding: 1px 5px;
  font-size: 15px;
  text-decoration: none;
  color: #57061e;
  font-weight: bold;
  align-items: center;
  display: flex;
}
.project-links a svg {
  margin: 3px;
}
.project-links a p {
  margin: 0px;
}
.project-description {
  margin-bottom: 50px;
}

.app-footer {
  text-align: center;
  padding: 10px;
  background-color: #99002f;
  color: white;
}

@media only screen and (min-width: 600px) {

  /* header */

  .app-header {
    flex-direction: row;
    align-items: center;
  }
  .app-header-logo {
   font-size: 8px;
  }
  .app-header-menu ul li a {
    font-size: 20px;
  } 
  
  
  /* about me*/

  .app-aboutme-content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .app-aboutme-content img {
    width: 30%;
    height: 30%;
  }
  .app-aboutme-content p {
    font-size: 22px;
  }
  .app-aboutme-socialmedia {
    justify-content: center;
  }
  .app-aboutme-socialmedia a {
    padding: 50px;
  }
  .app-aboutme-socialmedia a svg {
    width: 50px;
    height: 50px;
  }
  
  /* Skills*/
  
  .skill-boxes {
    width: 50%;
  }
  .app-cv-button {
    margin-top: 70px;
  }
  .app-cv-button-button {
    font-size: 20px;
    padding: 10px;
    width: 200px;
    color: white;
  }
  
  /* Projects */
 
  .project-row {
    width: 70%;
  }
  .project-row-information-header-and-links h3{
    font-size: 30px;
  }
  .project-description {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1000px) {

  .app-header-logo {
   font-size: 10px;
  }
  
  /* about me*/
  .app-aboutme {
    background: url(/static/media/backgroundwid.097204f6.jpg);
  }
  .app-aboutme-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .app-aboutme-content img {
    width: 20%;
    height: 20%;
  }
  .app-aboutme-content-text {
    padding: 50px;
  }
  
  /* Skills*/

  .skills-row {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .skill-boxes {
    padding: 5px;
    margin: 5px;
  }
  .app-cv-button-button {
    font-size: 20px;
    padding: 7px;
    width: 200px;
  }
  
  /* Projects */
  
  .project-row {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .project-row-image {
    width: 40%;
  }
  .project-row-image img {
    width: 100%;
  }
  .project-row-information {
    width: 90%;
    padding-left: 50px;
  }
  .project-row-information-header-and-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .project-links {
    display: flex;
    align-items: center;
  }

}
@media only screen and (min-width: 1700px) {
  
  /* about me*/

  .app-aboutme-content {
    width: 65%;
  }
  /* Skills*/
  .skills-row {
    width: 65%;
  }
  /* Projects */
  .project-row {
    width: 65%;
  }
  .project-row div {
    margin-top: 20px;
  }
}



.project-content {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 150px;
  padding-bottom: 50px;
}
.project-content p {
  font-size: 16px;
  color: rgb(53, 0, 0);
}
.project-content h2 {
  font-size: 28px;
  color: #690624;
}
.project-skill-list {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  list-style: none;
}
.project-skill-list li {
  border: 1px solid #8d062f;
  background-color: #e06f9159;
  border-radius: 3px;
  padding: 1px 10px;
  margin-right: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.project-skill-list li svg {
  padding: 5px;
  color: rgb(73, 1, 1);
}
.app-process-button {
  font-size: 15px;
  padding: 10px;
  width: 325px;
  background-color: #8d062f;
  color: white;
  border-radius: 3px;
}
.project-picture {
  text-align: center;
  margin-top: 50px;
}
.project-picture img {
  border-radius: 3px;
  box-shadow: 0px 6px 7px 0px #636363;
  width: 100%;
}
.project-picture p {
  font-style: italic;
  font-size: 15px;
}
.header-with-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project-content h3 {
  font-size: 25px;
  text-decoration: underline;
  color: #700726;
}
h4 {
  font-size: 20px;
  color: #700726;
}
h5 {
  font-size: 18px;
  color: #700726;
}

@media only screen and (min-width: 600px) {
  .project-content {
    width: 80%;
    padding-top: 120px;
  }
  .project-skill-list li svg {
    padding: 5px;
    color: rgb(73, 1, 1);
  }
  .project-picture img {
    width: 70%;
  }
}
@media only screen and (min-width: 1000px) {
  .project-content {
    width: 50%;
  }
  .project-content p {
    font-size: 17px;
  }
  .project-picture img {
    width: 70%;
  }
}
@media only screen and (min-width: 1700px) {
  .project-content {
    width: 40%;
  }
  .project-content p {
    font-size: 20px;
  }
  .project-content h2 {
    font-size: 35px;
  }
  .project-picture img {
    width: 50%;
  }
  .project-content h3 {
    font-size: 27px;
  }
  h4 {
    font-size: 23px;
  }
  h5 {
    font-size: 20px;
  }
}

